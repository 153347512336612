module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://world.blocktalk.tv"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@raae/gatsby-plugin-let-it-snow/gatsby-browser.js'),
      options: {"plugins":[],"intensity":"blizzard","colors":["#fff"],"duration":15,"season":{"start":"2001-12-01T00:00:00.000Z","end":"2001-01-04T00:00:00.000Z"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
