import './src/styles/global.css';
import React from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { shims, ethers } from 'ethers';

function getLibrary(provider) {
  return new ethers.providers.Web3Provider(provider);
}

export const wrapRootElement = ({ element }) => {
  return <Web3ReactProvider getLibrary={getLibrary}>{element}</Web3ReactProvider>
}
